import amygb from "../assets/images/amygb-logo.png";
import zoojoobe from "../assets/images/zjb-logo.svg";
import cat from "../assets/images/cat-logo.svg";
// import cat from "../assets/images/cat-logo.svg";
import lockheedimage from "../assets/images/xcf_files/lm_logo3.png";
import navairLogo from "../assets/images/navair_logo.png";
import nistlogo from "../assets/images/nist1_logo.png";
import nglogo from "../assets/images/ng_logo.png";

export default [
	{
		id: 4,
		name: "Northrop Grumman",
		designation: "Modeling and Simulation Engineer",
		dated: "August 2022 - Present",
		logo: nglogo,
		companyLink: "https://www.northropgrumman.com",
		description:
			"<p>Created models and simulations in AFSIM for demonstration of disruptive Northrop Grumman capabilities in the context of missiles defense, projectiles, command and control (C2), and EOIR satellites. Product owner role for two MS&A teams, leading to successful internal and external customer demonstrations. Led teams of 5+ people, organizing and developing innovative MS&A solutions in an organized manner. Developed vast understanding of the missile defense kill-chain and existing weapon system architectures</p>"
	},
	{
		id: 3,
		name: "Lockheed Martin Space",
		designation: "Satellite Systems Engineer",
		dated: "August 2020 - August 2022",
		logo: lockheedimage,
		companyLink: "https://www.lockheedmartin.com/en-us/capabilities/space.html",
		// description:
		// 	"<p>Lockheed martrin space here</p>"
	},
	{
		id: 2,
		name: "Naval Air (NAVAIR) Systems Command",
		designation: "Diagnostics Engineering Intern",
		dated: "May 2019 - August 2019",
		logo: navairLogo,
		companyLink: "https://www.navair.navy.mil/",
		description:
			"<p>Focus of internship was to conduct diagnostics on vehicles in the fleet including the Sikorsky CH-53K and Northrup Grumman MQ-8C helicopter. Python algorithms were generated to decode binary data based on ICD's and technical guidelines. HTML scripts were also generated using toosl such as Bokeh and Plotly to show exceedances and other valuable diagnostic information. This internship allowed me to combine my engineering knowledge and data science skills to better understand and display flight data for other personel.</li></p>"
	},
	{
		id: 1,
		name: "National Institute for Standard and Technology",
		designation: "Software Researcher",
		dated: "May 2018 - August 2018",
		logo: nistlogo,
		companyLink: "https://www.nist.gov/",
		description:
			"<p>Implentation and testing of MTConnect and OPC-UA Companion specificaiton was testing. This companion is a mechanism which allows for standardized, secure, and efficient information transfer between MTConnect based machines and OPC-UA hardware. Test cases were generated, code was implemented for testing, and the model was validated. Server/client implementation was completed with C#, and a parser, probe, and user interface was created in Python. This research fellowship gave me a better grasp on programming and how systems communicate through network architectures. From this, an MSEC 2019 Conference paper was submitted with myself as the primary author. The repository for this research can be found on Github.</p>"
	}
];
